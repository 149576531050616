
aside#home-customers,
aside#inner-customers
{
    #customers-carousel
    {
        text-align: center;

        .customers-item
        {
            width: calc(25% - 1.25em);
            margin-right: 1em;
            display: inline-block;
            vertical-align: middle;
            float: none !important;

            > img
            {
                max-width: 100%;
            }
        }
    }

    .bx-prev, .bx-next
    {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        font-family: 'FontAwesome';
        font-size: 50px;
        text-decoration: none;
        color: $colorAlternate;
        display: block;
    }

    .bx-prev
    {
        content: "\f100";
    }

    .bx-next
    {
        content: "\f101";
    }

    @media(max-width: 1119px)
    {
        .bx-controls
        {
            display: none;
        }
    }
}

aside#home-customers
{
    padding: 1em 0 0;

    h3
    {
        text-transform: uppercase;
        text-align: center;
        color: $colorAccent;

        > span.strike-clear
        {
            background-color: #fff;
        }

        &:after
        {
            border-color: $colorAccent;
        }
    }

    .bx-wrapper
    {
        max-width: 100% !important;
        margin: 40px 0;
    }

    .bx-prev
    {
        right: calc(100% + 30px);
    }

    .bx-next
    {
        left: calc(100% + 30px);
    }
}

aside#inner-customers
{
    text-align: center;

    @media(min-width: 768px)
    {
        margin-bottom: -2em;
    }

    @media(max-width: 767px)
    {
        margin-top: 5em;
    }

    h3
    {
        text-transform: uppercase;
        color: $colorAccent;
        border-top: 3px solid $colorAccent;
        padding-top: 1.5em;
        margin-top: 0;
        display: inline-block;
    }

    .bx-wrapper
    {
        width: calc(100% - 150px) !important;
        max-width: 320px !important;
        margin: 16px auto;
    }

    .bx-prev
    {
        right: calc(100% + 80px);
    }

    .bx-next
    {
        left: calc(100% + 80px);
    }
}
