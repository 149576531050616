aside#home-callback
{
    background-color: $colorAlternate;
    color: #fff;
    font-family: $altFont;
    padding: 1em 0 0;

    h3
    {
        color: #fff;
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 42px;

        > span.strike-clear
        {
            background-color: $colorAlternate;
        }

        &:after
        {
            border-color: #fff;
        }
    }

    form
    {
        padding-bottom: 2em;
    }

    select.form-control
    {
        padding: 6px 10px;
    }

    .form-control
    {
        font-family: $mainFont;
        border-radius: 0;
        color: $colorBaseMid;
        font-size: 16px;
        font-weight: 300;
        border: 0;
        padding: 6px 18px;
        height: 45px;

        &::-webkit-input-placeholder
        {
            color: $colorBaseMid;
        }

        &:-moz-placeholder
        {
            color: $colorBaseMid;
        }

        &::-moz-placeholder
        {
            color: $colorBaseMid;
        }

        &:-ms-input-placeholder
        {
            color: $colorBaseMid;
        }

        &::placeholder
        {
            color: $colorBaseMid;
        }
    }

    .btn-callback
    {
        background-color: transparent;
        border: 2px solid #fff;
        border-radius: 0;
        font-family: $altFont;
        font-size: 14px;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 2px;
        color: #fff;
        padding: 2px 12px;
        height: 43px;
        display: block;
        width: 100%;
    }

    .form-group
    {
        margin-bottom: 15px;
    }
}


aside#page-callback
{
    margin-bottom: 4em;

    h3
    {
        color: $colorAccent;
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 1.5em;
        border-top: 3px solid $colorAccent;
        padding-top: 1em;

        @media(max-width: 767px)
        {
            margin-top: 2.5em;
        }
    }

    .form-control
    {
        font-family: $mainFont;
        border-radius: 0;
        color: $colorBaseMid;
        font-size: 16px;
        font-weight: 300;
        border: 2px solid $colorAccent;

        &::-webkit-input-placeholder
        {
            color: $colorBaseMid;
        }

        &:-moz-placeholder
        {
            color: $colorBaseMid;
        }

        &::-moz-placeholder
        {
            color: $colorBaseMid;
        }

        &:-ms-input-placeholder
        {
            color: $colorBaseMid;
        }

        &::placeholder
        {
            color: $colorBaseMid;
        }
    }

    .btn-callback
    {
        background-color: $colorAccent;
        border-radius: 0;
        font-family: $altFont;
        font-size: 14px;
        text-transform: uppercase;
        letter-spacing: 4px;
        color: #fff;
        padding: 2px 2rem;
        height: 43px;
        min-width: 33%;
        width: auto;
        margin-left: auto;
        font-weight: 500;
        display: block;
    }
}

.form-group
{
    margin-bottom: 15px;

    .btn-block
    {
        width: 100%;
    }
}