.timeline
{
    box-sizing: border-box;
    position: relative;

    *,
    *::before,
    *::after
    {
        box-sizing: inherit;
    }

    &:not(.timeline--horizontal)::before
    {
        background-color: #DDD;
        bottom: 0;
        content: '';
        left: 50%;
        margin-left: -2px;
        position: absolute;
        top: 0;
        width: 4px;
        z-index: 1;

        @media (max-width: 767px)
        {
            left: calc(100% - 20px);
        }
    }
}

.timeline__wrap
{
    overflow: hidden;
    position: relative;
    z-index: 2;
}

.timeline__item
{
    font-size: 1rem;
    padding: 10px 40px 10px 0;
    position: relative;
    width: 50%;
    z-index: 2;

    &::after
    {
        background-color: #FFF;
        border: 4px solid #DDD;
        border-radius: 50%;
        content: '';
        height: 20px;
        position: absolute;
        right: -10px;
        transform: translateY(-50%);
        top: 50%;
        width: 20px;
        z-index: 1;
    }

    &.animated
    {
        animation-duration: 1s;
        animation-fill-mode: both;
        opacity: 0;
    }

    &.fadeIn
    {
        animation-name: fadeIn;
    }

    @media (max-width: 767px)
    {
        width: calc(100% - 20px);
    }
}

.timeline__item--left
{
    left: 0;
}

@media (min-width: 768px)
{
    .timeline__item--right
    {
        left: 50%;
        padding: 10px 0 10px 40px;

        &::after
        {
            left: -10px;
        }

        .timeline__content
        {
            &::before
            {
                border-bottom: 10px solid transparent;
                border-right: 12px solid #CCC;
                border-left: none;
                border-top: 10px solid transparent;
                left: -12px;
            }

            &::after
            {
                border-bottom: 9px solid transparent;
                border-right: 11px solid #FFF;
                border-left: none;
                border-top: 9px solid transparent;
                left: -10px;
            }
        }
    }
}

.timeline__content
{
    background-color: #FFF;
    border: 1px solid #CCC;
    border-radius: 10px;
    color: #333;
    display: block;
    padding: 20px;
    position: relative;

    &::before,
    &::after
    {
        content: '';
        height: 0;
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        width: 0;
    }

    &::before
    {
        border-bottom: 10px solid transparent;
        border-left: 12px solid #CCC;
        border-top: 10px solid transparent;
        right: -12px;
        z-index: 1;
    }

    &::after
    {
        border-bottom: 9px solid transparent;
        border-left: 11px solid #FFF;
        border-top: 9px solid transparent;
        right: -10px;
        z-index: 2;
    }

    h2
    {
        font-size: 20px;
        font-weight: 700;
        margin: 0 0 10px;
    }

    p
    {
        font-size: 15px;
        line-height: 1.5;
        margin-bottom: 10px;
    }
}

.timeline--horizontal
{
    font-size: 0;
    padding: 0 50px;
    overflow: hidden;
    white-space: nowrap;

    .timeline-divider
    {
        background-color: #DDD;
        display: block;
        height: 4px;
        left: 40px;
        position: absolute;
        transform: translateY(-50%);
        right: 40px;
        z-index: 1;
    }

    .timeline__items
    {
        transition: all 0.8s;
        will-change: transform;
    }

    .timeline__item
    {
        display: inline-block;
        left: 0;
        padding: 0 0 40px;
        position: relative;
        transition: none;
        vertical-align: top;
        white-space: normal;

        &::after
        {
            left: 50%;
            right: auto;
            transform: translate(-50%, -50%);
            top: 100%;
        }

        .timeline__item__inner
        {
            display: table;
            height: 100%;
            width: 100%;
        }

        .timeline__content__wrap
        {
            display: table-cell;
            margin: 0;
            padding: 0;
            vertical-align: bottom;
        }

        .timeline__content::before
        {
            border-left: 12px solid transparent;
            border-right: 12px solid transparent;
            border-top: 12px solid #CCC;
            left: 50%;
            right: auto;
            transform: translateX(-50%);
            top: 100%;
        }

        .timeline__content::after
        {
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-top: 10px solid #FFF;
            left: 50%;
            right: auto;
            transform: translateX(-50%);
            top: 100%;
        }

        &--bottom
        {
            padding: 40px 0 0;

            &::after
            {
                top: 0;
            }

            .timeline__content__wrap
            {
                vertical-align: top;
            }

            .timeline__content::before
            {
                border-bottom: 12px solid #CCC;
                border-left: 12px solid transparent;
                border-right: 12px solid transparent;
                border-top: none;
                bottom: 100%;
                top: auto;
            }

            .timeline__content::after
            {
                border-bottom: 10px solid #FFF;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-top: none;
                bottom: 100%;
                top: auto;
            }
        }
    }
}

.timeline-nav-button
{
    background-color: #FFF;
    border: 2px solid #DDD;
    border-radius: 50px;
    box-sizing: border-box;
    box-shadow: none;
    cursor: pointer;
    display: block;
    height: 40px;
    outline: none;
    position: absolute;
    text-indent: -9999px;
    transform: translateY(-50%);
    top: 50%;
    width: 40px;
    z-index: 10;

    &:disabled
    {
        opacity: .5;
        pointer-events: none;
    }

    &::before
    {
        background-position: center center;
        background-repeat: no-repeat;
        content: '';
        display: block;
        height: 14px;
        left: 50%;
        position: absolute;
        transform: translateX(-50%) translateY(-50%);
        top: 50%;
        width: 8px;
    }

    &--prev
    {
        left: 0;

        &::before
        {
            background-image: url('../images/arrow-left.svg');
        }
    }

    &--next
    {
        right: 0;

        &::before
        {
            background-image: url('../images/arrow-right.svg');
        }
    }
}

.timeline--mobile
{
    padding: 0;

    &::before
    {
        left: 10px !important;
        margin: 0 !important;
    }

    .timeline__item
    {
        left: 0;
        padding-left: 40px;
        padding-right: 0;
        width: 100%;

        &::after
        {
            left: 2px;
            margin: 0;
        }

        .timeline__content
        {
            &::before
            {
                left: -12px;
                border-bottom: 12px solid transparent;
                border-right: 12px solid #CCC;
                border-left: none;
                border-top: 12px solid transparent;
            }

            &::after
            {
                left: -10px;
                border-bottom: 10px solid transparent;
                border-right: 10px solid #FFF;
                border-left: none;
                border-top: 10px solid transparent;
            }
        }
    }
}

@keyframes fadeIn
{
    0%
    {
        opacity: 0;
        top: 70px;
    }

    100%
    {
        opacity: 1;
        top: 0px;
    }
}

@keyframes liftUp
{
    0%
    {
        top: 0px;
    }

    100%
    {
        top: -15px;
    }
}
