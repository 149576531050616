@import "inc/variables";
@import "inc/bootstrap/defer";

$la-css-prefix: la;
@import "~line-awesome/dist/line-awesome/scss/line-awesome";
@import "~font-awesome/scss/font-awesome";
@import "~jssocials/styles/jssocials";
@import "~jssocials/styles/jssocials-theme-flat";

@import "inc/colorbox";
@import "inc/la-fix";

@import "parts/callback";
@import "parts/customers";
@import "parts/footer";
@import "parts/bxslider";
@import "parts/pagination";

@import "../../ext/timeline/scss/timeline";