

footer#page-footer
{
    margin: 0 0;
    padding: 3em 0 4em;
    background-color: $colorAccent;
    color: #fff;
    text-transform: uppercase;
    font-weight: 300;
    font-size: 14px;

    h3
    {
        color: #fff;
        display: inline-block;
        margin: 0 0 .75em;
    }

    img
    {
        max-width: 200px;
        margin-bottom: .333rem;
    }

    a,
    a:hover,
    a:focus,
    a:active
    {
        color: #fff;
        text-decoration: none;
        font-weight: normal;
    }

    li
    {
        margin: 0;
        line-height: 2em;
    }

    p
    {
        margin-bottom: .25rem;
    }

    .row
    {
        border-top: 4px solid $colorInverse;
        margin-top: 3rem;
        margin-bottom: 3rem;
        padding-top: 1.5rem;

        > *
        {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 1.5rem;
        }
    }

    p:last-of-type
    {
        margin-bottom: 0;
    }

    @media (max-width: 767px)
    {
        .row > *
        {
            margin-top: 3rem;
        }

        .pull-bottom
        {
            display: none;
        }
    }
}

footer#page-copyright
{
    padding: 1.5em 0 0;
    font-size: 0.7777em;

    a
    {
        color: $colorBaseMid;
        text-decoration: none;

        &:hover,
        &:focus,
        &:active
        {
            text-decoration: underline;
        }
    }

    @media(min-width: 992px)
    {
        .desktop-right
        {
            float: right;
        }
    }
}
