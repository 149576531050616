/* bxSlider RESET */
.bx-wrapper
{
    position: relative;
    margin-bottom: 60px;
    padding: 0;
    *zoom: 1;
    -ms-touch-action: pan-y;
    touch-action: pan-y;

    img
    {
        max-width: 100%;
    }
}

.bxslider
{
    margin: 0;
    padding: 0;
}

ul.bxslider
{
    list-style: none;
}

.bx-viewport
{
    -webkit-transform: translatez(0);
}
