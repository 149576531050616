.pagination
{
    display: flex;
    padding: 1rem 0;

    .page-prev,
    .page-next
    {
        background-color: white;
        color: $colorAccent;
        padding: .25rem 1rem;
        text-decoration: none;
        cursor: pointer;
        border: 2px solid currentColor;
        flex-basis: 10rem;
        text-align: center;

        &::before
        {
            font-weight: 500;
            text-transform: uppercase;
        }

        i
        {
            display: none;
        }

        &.disabled
        {
            opacity: .5;
            cursor: not-allowed;
        }
    }

    .page-prev
    {
        &::before
        {
            content: "Previous";
        }
    }

    .page-next
    {
        &::before
        {
            content: "Next";
        }
    }

    .page-current
    {
        &::before
        {
            content: "Page";
            margin-right: .333em;
        }

        background-color: white;
        color: $colorAccent;
        @include uppercaseText();
        padding: .25rem 1rem;
        font-weight: bold;
        display: flex;
        align-items: center;
        flex-grow: 1;
        text-align: center;
        justify-content: center;
    }
}
